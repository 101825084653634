//      BASE SCSS
.auto{
	margin-left:auto;
	margin-right:auto;
	float:none;
}
.floatleft, .fl{
	float:left;
}
.floatright, .fr{
	float:right;
}
.absolute, .abs{
	position:absolute;
}
.relative, .relt{
	position:relative;
}
.fixed{
	position:fixed;
}
.z10{
	z-index:10;
}
.z20{
	z-index:20;
}
.z30{
	z-index:30;
}
.both{
	clear:both;
}
.width100, .w100{
	width:100%;
}
.width50, .w50{
	width:50%;
}
.height100, .h100{
	height:100%;
}
.height50, .h50{
	height:50%;
}
.vh100{
	height:100vh
}
.vh50{
	height:50vh;
}
.none{
	display:none;
}
.noneimp{
	display:none !important;
}
.hidden, .hdn{
	overflow:hidden;
}
.pointer{
	cursor:pointer;
}
.aligncenter, .alcenter{
	text-align:center;
}
.replace{
	text-indent: 101%;
	white-space: nowrap;
	overflow: hidden;
}
.transition, .trn{
	@include transition(all 0.3s);
}
.transitionfast, .trnf{
	@include transition(all 0.15s);
}
.middle{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}
.menu_burger_button{
    width:30px;
	span{
		display:block;
		background-color:#cccccc;
		height:4px;
		margin-bottom:7px;
		@include transition(all 0.3s);
	}
}
.menu_burger_button:hover{
	span{
		background-color:#000000;
	}
}
.menu_burger_close{
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    top: 0px;
    &::before, &::after{
        content:"";
        position: absolute;
        z-index: 5;
        width:24px;
        height: 3px;
        border-radius: 10px;
        transform: rotateZ(-45deg);
        background-color: yellow;
        top: 50%;
        left: calc(50% - 12px);
    }
    &::before{
        transform: rotateZ(-45deg);
    }
    &::after{
        transform: rotateZ(45deg);
    }
}
.menu_burger{
	width:100%;
	height:100%;
	position:fixed;
	z-index:999;
	top:0px;
    left:0px;
    background-color:#dddddd;
	@include transition(all 0.5s);
}
.menu_burger.vertical{
	@include translate(0%,-100%);
}
.menu_burger.vertical.bottom{
	@include translate(0%,100%);
}
.menu_burger.horizontal{
	@include translate(-100%,0%);
}
.menu_burger.horizontal.right{
	@include translate(100%,0%);
}
.menu_burger.on, .menu_burger.on{
    -moz-transform: translate(0%,0%) !important;
    -o-transform: translate(0%,0%) !important;
    -ms-transform: translate(0%,0%) !important;
    -webkit-transform: translate(0%,0%) !important;
    transform: translate(0%,0%) !important;
}
.flex{
	display: -webkit-box; 
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex; 
	display: flex;
}
.grid{
    display: grid;
}
.whats_float{
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 20;
    @include bgPos(no-repeat, center center, 30px auto);
    background-image: url(../img/whats.svg);
    background-color: #25D366;
    border-radius: 50%;
    @extend .trn;
    &:hover{
        background-color: #56ED88;
    }
}
.before{
    &::before{
        content:"";
        position: absolute;
        z-index: 1;
        left:0px;
        top:0px;
    }
}
.after{
    &::after{
        content:"";
        position: absolute;
        z-index: 1;
        left:0px;
        top:0px;
    }
}
.icon{
    @extend .before;
    &::before{
        @include bgPos(no-repeat, center center, auto auto);
        width: 100%;
        height: 100%;
    }
}
.iconHover{
    @extend .before;
    @extend .after;
    position: relative;
    &::before, &::after{
        @include bgPos(no-repeat, center center, auto auto);
        width: 100%;
        height: 100%;
        @extend .trn;
    }
    &::after{
        opacity: 0;
    }
    &:hover::before{
        opacity: 0;
    }
    &:hover::after{
        opacity: 1;
    }
}

.maskBox{
    position: absolute;
    z-index: 5;
    left:0px;
    top:0px;
    width:100%;
    height: 100%;
}

.imgCover{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container{
    margin-left: auto;
    margin-right: auto;
    float: none;
    max-width: 1216px;

    @include mobile{
        padding-left: 16px;
        padding-right: 16px;
    }

    &__header{
        max-width: 1230px;
    }

    &--large{
        max-width: 1296px;
    }

    &--short{
        max-width: 1048px;
    }

    &--founders{
        max-width: 1030px;
    }

    &--blog{
        max-width: 1074px;
    }

    &--privacy{
        max-width: 1440px;
    }

    &--nofound{
        max-width: 1054px;
    }

    &--inrecruiting{
        max-width: 1112px;
    }
}

.flex{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.font__title{
    font-family: $fontTitle;
}

@include mobile{

}