.footer{
    padding-top: 65px;

    @include mobile{
        padding-top: 32px;
    }

    &__content{
        @extend .flex;

        @include mobile{
        }
    }

    &__logo{
        @include flex(180px);
        
        @include mobile{
            @include flex(33.33%);
        }

        figure img{
            max-width: 100%;

            @include mobile{
                max-width: 58px;
            }
        }

        .footer__about{
            @include mobile{
                display: block;
                @include flex(100%);
                padding-top: 15px;
            }
        }
    }

    &__menu{
        @include flex(calc(100% - 390px));
        @extend .flex;
        
        @include mobile{
            @include flex(66.66%);
        }

        + .footer__about{
            @include mobile{
                display: none;
            }
        }

        &_col1{
            @include flex(40.1%);
            @extend .flex;
            
            @include mobile{
                @include flex(50%);
            }
        }
        &_col2{
            @include flex(59.9%);
            @extend .flex;
            
            @include mobile{
                @include flex(50%);
            }
        }
    }

    &__about{
        @include flex(210px);

        &_title{
            color: var(--cores-software-cinza-azulado);
            font-size: 18px;
            font-weight: 700;
            line-height: 111%;
            margin-bottom: 12px;

            @include mobile{
                font-size: 8px;
                line-height: 137%;
                margin-bottom: 5px;
                padding-right: 10px;
            }
        }

        &_text{
            color: rgba(35, 48, 66, 0.75);
            font-size: 10px;
            font-weight: 500;
            line-height: 171%;
            margin-bottom: 16px;

            @include mobile{
                font-size: 8px;
                line-height: 100%;
                padding-right: 18px;
            }
        }

        &_cta{
            margin-bottom: 32px;
        }

        &_zucchetti{

            @include mobile{
                display: none;
            }

            p{
                color: #000;
                font-size: 9.5px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 3px;
            }
        }
    }

    .fmenu{
        padding-right: 5px;

        @include mobile{
            padding-right: 10px;
        }

        &--col_1{
            @include flex(53.11%);

            @include mobile{
                @include flex(100%);
            }
        }
        &--col_2{
            @include flex(46.89%);

            @include mobile{
                @include flex(100%);
                margin-top: 15px;
            }
        }
        &--col_3{
            @include flex(49.08%);

            @include mobile{
                @include flex(100%);
            }
        }
        &--col_4{
            @include flex(50.92%);

            @include mobile{
                @include flex(100%);
            }
        }

        &__title{
            margin-bottom: 23px;

            @include mobile{
                margin-bottom: 8px;
            }

            &--secondary{
                padding-top: 30px;

                @include mobile{
                    padding-top: 15px;
                }
            }
        }

        &__title, &__title a{
            color: var(--footer-magenta);
            font-family: $fontDefault;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;

            @include mobile{
                font-size: 14px;
            }
        }

        &__item{
            margin-bottom: 8px;

            @include mobile{
                margin-bottom: 5px;
            }

            &--lang_selector{
                select{
                    background-image: url(../assets/svg/icon-language.svg);
                    padding-left: 27px;
                    height: 20px;
                    @include bgPos(no-repeat, left center, auto auto);
    
                    @include mobile{
                        background-size: auto 13px;
                    }
                }
            }
        }

        &__item, &__link{
            color: var(--cores-software-cinza);
            font-size: 14px;
            font-weight: 500;
            line-height: normal; 

            @include mobile{
                font-size: 8px;
            }
        }
        &__link{
            display: inline-block;
            padding-top: 1px;
            padding-bottom: 1px;
            @extend .trn;

            &:hover, &:active{
                color: var(--cores-da-marca-new-magenta); 
            }

            &--icon{
                padding-left: 27px;
                @include bgPos(no-repeat, left center, auto auto);

                @include mobile{
                    background-size: auto 13px;
                }
            }

            &--mail{
                background-image: url(../assets/svg/icon-mail.svg);
            }
            
            &--lang{
                background-image: url(../assets/svg/icon-language.svg);
            }
        }
    }

    &__copy{
        margin-top: 28px;
        padding-top: 32px;
        padding-bottom: 28px;
        border-top: 2px solid rgba(255, 79, 196, 0.14);

        @include mobile{
            margin-top: 20px;
            padding-bottom: 16px;
            padding-top: 28px;
        }

        p{
            color: var(--footer-magenta);
            font-size: 14px;
            font-weight: 400;
            line-height: normal; 
            
            @include mobile{
                text-align: center;
            }
        }
    }
}