//		NORMALIZE / RESET
*{
	margin:0px;
	padding:0px;
	color:inherit;
	font-size:inherit;
	font-family:inherit;
	font-weight:inherit;
    line-height: inherit;
	background-color:transparent;
	border:0px none transparent;
    text-align:left;
    box-sizing: border-box;
}
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
html,body{
	color:#000000;
	font-size:12px;
	font-family:$fontDefault,Arial,Helvetica,sans-serif;
	font-weight:normal;
    line-height: normal;
}
body{
	background-color:#ffffff;
	overflow:auto;
	margin:0px;
}
header, section, footer, aside, nav, article, figure, details, figcaption, hgroup, main, menu, summary, address, form{
	display: block;
}
address{
	font-style:normal;
}
a {
    background-color: transparent;
    color:inherit;
	text-decoration:none;
}
a, a:hover, a:active{
    outline: 0;
}
strong, b{
	font-weight:bold;
}
ul{
	li{
		list-style:none;
	}
}
img {
    border: 0;
}
pre {
    overflow: auto;
}
code, kbd, pre, samp {
    font-family: monospace, monospace;
    font-size: 12px;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
button, input[type="button"], input[type="submit"], input[type="reset"]{
    cursor:pointer;
    -webkit-appearance: button;
}
button[disabled], input[disabled] {
    cursor: default;
}
button, input, textarea, a{
	outline:none !important;
	box-shadow:none !important;
}
input, button{
	border:0px none transparent;
	background-color:transparent;
}
button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input {
    line-height: normal;
}
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
legend {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td, th {
    padding: 0;
}
::-webkit-input-placeholder {
    color:inherit;
}
:-moz-placeholder {
    color:inherit;
    opacity:1;
}
::-moz-placeholder {
    color:inherit;
    opacity:1;
}
:-ms-input-placeholder {
    color:inherit;
}
.tiny{
	ul{
		li{
			list-style: disc inside;
		}
	}
}
h1,h2,h3,h4,h5,h6{
	font-family:$fontTitle,Arial,Helvetica,sans-serif;
}