:root{
    --escala-de-cinzas-cinza-1:#737070;
    --escala-de-cinzas-cinza-2:#B3AFAF;
    --escala-de-cinzas-cinza-3:#D9D4D4;
    --escala-de-cinzas-cinza-4:#F2EDED;
    --escala-de-cinzas-cinza-5:#FFFAFA;
    --cores-da-marca-new-magenta:#E66CF7;
    --cores-da-marca-amarelofy:#FFF98B;
    --cores-da-marca-elofy-blue-20:#001BA6;
    --cores-da-marca-soft-gray:#F1EDEC;
    --cores-software-branco-azulado:#F7F8FC;
    --cores-software-cinza-claro:#BFC4CD;
    --cores-software-cinza:#858B98;
    --cores-software-cinza-escuro:#404040;
    --cores-software-cinza-azulado:#233042;
    --elofy-blue-2018:#EDF0FF;
    --new-magenta-18:#FBE0FB;
    --blog-magenta:#F27FFD;
    --footer-magenta:#E86BF7;
}

//      GELIX FONT
@font-face{
	font-family:Gellix;
	src:url('../fonts/gellix/Gellix-Regular.woff2') format('woff2'),
		url('../fonts/gellix/Gellix-Regular.woff') format('woff');
	/*src:url("../fonts/gellix/Gellix-Regular.otf"), url("../fonts/gellix/Gellix-Regular.ttf") format("truetype");*/
	font-weight:400;   
	font-style:normal;
}
@font-face{
	font-family:Gellix;
	src:url('../fonts/gellix/Gellix-Medium.woff2') format('woff2'),
		url('../fonts/gellix/Gellix-Medium.woff') format('woff');
	/*src:url("../fonts/gellix/Gellix-Medium.otf"), url("../fonts/gellix/Gellix-Medium.ttf") format("truetype");*/
	font-weight:500;   
	font-style:normal;
}
@font-face{
	font-family:Gellix;
	src:url('../fonts/gellix/Gellix-SemiBold.woff2') format('woff2'),
		url('../fonts/gellix/Gellix-SemiBold.woff') format('woff');
	/*src:url("../fonts/gellix/Gellix-SemiBold.otf"), url("../fonts/gellix/Gellix-SemiBold.ttf") format("truetype");*/
	font-weight:600;   
	font-style:normal;
}
@font-face{
	font-family:Gellix;
	src:url('../fonts/gellix/Gellix-Bold.woff2') format('woff2'),
		url('../fonts/gellix/Gellix-Bold.woff') format('woff');
	/*src:url("../fonts/gellix/Gellix-Bold.otf"), url("../fonts/gellix/Gellix-Bold.ttf") format("truetype");*/
	font-weight:700;   
	font-style:normal;
}

//      GOTHAM FONT
@font-face{
	font-family:Gotham;
	src:url("../fonts/gotham/GothamCond-Book.otf");
	/*src:url('../fonts/gotham/GothamCond-Book.woff2') format('woff2'),
		url('../fonts/gotham/GothamCond-Book.woff') format('woff'), url("../fonts/gotham/GothamCond-Book.ttf") format("truetype");*/
	font-weight:400;   
	font-style:normal;
}
@font-face{
	font-family:Gotham;
	src:url("../fonts/gotham/GothamCond-Medium.otf");
	/*src:url('../fonts/gotham/GothamCond-Medium.woff2') format('woff2'),
		url('../fonts/gotham/GothamCond-Medium.woff') format('woff'), url("../fonts/gotham/GothamCond-Medium.ttf") format("truetype");*/
	font-weight:500;   
	font-style:normal;
}
@font-face{
	font-family:Gotham;
	src:url("../fonts/gotham/GothamCond-Bold.otf");
	/*src:url('../fonts/gotham/GothamCond-Bold.woff2') format('woff2'),
		url('../fonts/gotham/GothamCond-Bold.woff') format('woff'), url("../fonts/gotham/GothamCond-Bold.ttf") format("truetype");*/
	font-weight:700;   
	font-style:normal;
}

/*
    @include flex(100%);
    @include grid(1fr, 10px, 10px);
    @include gap(10px, 10px);
    @include transition(all 0.3s);
	@include translate(0%,-100%);
    @include bgPos(no-repeat, center center, auto auto);
*/

//      FONTS AND COLORS
$fontDefault: Gellix;
$fontTitle: Gotham;
$colorBlack: #000000;
$colorGray: #555555;

$headerHeight:80px;
$headerHeightMobile:55px;
$blogImageHeight:216px;
$blogImageHeightMobile:185px;
$testimonialHeight:408px;

//      SASS MIXINs

//		TRANSITION
@mixin transition($property) {
	-webkit-transition:$property;
	-moz-transition:$property;
	-o-transition:$property;
	transition:$property;
}

//		TRANSLATE
@mixin translate($vx, $vy) {
	-moz-transform: translate($vx,$vy);
	-o-transform: translate($vx,$vy);
	-ms-transform: translate($vx,$vy);
	-webkit-transform: translate($vx,$vy);
	transform: translate($vx,$vy);
}

//		BACKGROUND IMAGE POSITION
@mixin bgPos($repeat, $position, $size) {
	background-repeat:$repeat;
	background-position:$position;
	background-size:$size;
}

//		FLEX ITEM
@mixin flex($property) {
    width: $property;
    max-width: $property;
    flex: 0 0 $property;
}

//		GRID ITEM
@mixin grid($template, $colgap, $rowgap) {
    display: grid;
    grid-template-columns: $template;
    grid-column-gap: $colgap;
    grid-row-gap: $rowgap;
}

//		GRID GAP
@mixin gap($colgap, $rowgap) {
    grid-column-gap: $colgap;
    grid-row-gap: $rowgap;
}

//		WIDTH 1600
@mixin desk {
    @media (max-width: 1799px) {
        @content;
    }
}

//		WIDTH 1350
@mixin note {
    @media (max-width: 1399px) {
        @content;
    }
}

//		WIDTH 1200
@mixin mac {
    @media (max-width: 1299px) {
        @content;
    }
}

//		WIDTH 768
@mixin tablet {
    @media (max-width: 1159px) {
        @content;
    }
}

//		WIDTH 415
@mixin mobile {
    @media (max-width: 1199px) {
        @content;
    }
}

//		WIDTH 320
@mixin cell {
    @media (max-width: 349px) {
        @content;
    }
}

//		HEIGHT AND WIDTH
@mixin tabletH {
    @media (max-height: 699px) and (max-width: 1159px) {
        @content;
    }
}