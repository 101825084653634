@import "utils/variables";
@import "utils/reset";
@import "utils/base";
@import "utils/components";
@import "common/header";

html {
    margin: 0px !important;
} 
 
body.noscroll{
    overflow: hidden;
}

main.main{
    padding-top: $headerHeight;
    overflow: hidden;

    @include mobile{
        padding-top: $headerHeightMobile;
    }
}

main.main_post{
    padding-top: $headerHeight;
    background-color: #fff;
    
    @include mobile{
        padding-top: $headerHeightMobile;
    }
}

body{
    background-color: var(--escala-de-cinzas-cinza-5); 
}

/*      HOME        */
.banner{

    .container{
        position: relative;
        z-index: 5;
    }

    &__home, &__about, &__blog, &__policy{
        background-color: #001CA6;
        position: relative;
        
        .container{
            position: relative;
            z-index: 5;
        }
    }

    &__home{
        padding-top: 104px;
        padding-bottom: 84px;

        .banner__tela{
            margin-right: 0px;
        }

        .banner__left {
            padding-bottom: 40px;

            @include mobile{
                padding-bottom: 0px;
                max-width: 330px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @include mobile{
            padding-bottom: 60px;
            padding-top: 18px;
        }
    }

    &__about{
        padding-top: 117px;
        padding-bottom: 121px;

        @include mobile{
            padding-bottom: 58px;
            padding-top: 42px;
        }

        .banner__img{
            border-radius: 25px;
        }
    }

    &__solution{
        padding-top: 124px;
        padding-bottom: 15px;

        @include mobile{
            padding-top: 10px;
        }
        
        .container{
            position: relative;
            z-index: 5;
        }
        
        .banner__right{
            @include mobile{
                margin-top: 33px;
            }
        }
    }

    &__blog{
        padding-top: 65px;
        padding-bottom: 70px;

        @include mobile{
            padding-bottom: 30px;
            padding-top: 24px;
        }
        
        .container{
            position: relative;
            z-index: 5;
        }

        &_link{
            padding-top: 16px;

            a{
                color: var(--cores-da-marca-amarelofy);
                font-size: 16px;
                font-weight: 500;
                line-height: 175%;
                text-transform: uppercase;
                span{
                    text-decoration-line: underline;
                }
            }
        }

        .banner__left {
            @include mobile{
                order: 2;
            }
        }
        
        .banner__right{
            max-width: 575px;
            
            @include mobile{
                order: 1;
                margin-top: 0px;
            }
        }

        .banner__category {
            @include mobile{
                display: none;
            }
        }
    }

    &__policy{
        padding-top: 86px;
        padding-bottom: 98px;

        @include mobile{
            padding-bottom: 120px;
            overflow: visible;
        }

        &_img{
            display: block;
            position: absolute;
            right: 30px;
            bottom: -28px;
            border-radius: 20px;
            
            @include mobile{
                right: 44px;
                max-width: 95px;
            }
        }
    }

    &__content{
        width: 100%;
        @extend .flex;
        align-items: center;
        justify-content: space-between;

        &--policy{
            justify-content: center;
        }
    }

    &__left, &__right{
        max-width: 50%;
        position: relative;

        @include mobile{
            max-width: 100%;
            width: 100%;
        }
    }

    &__right{
        @include mobile{
            margin-top: 44px;
        }
    }

    &__center{
        max-width: 592px;
        position: relative;
    }

    &__cta{
        padding-top: 25px;

        @include mobile{
            padding-top: 57px;
            text-align: center;
            width: 100%;

            .btn--vendas{
                width: 232px;
                height: 32px;
            }
        }
    }

    &__tela{
        position: relative;
        z-index: 5;
        margin-right: 65px;

        @include mobile{
            display: flex;
            justify-content: center;
        }
    }

    &__photo{
        @include mobile{
            display: flex;
            justify-content: center;
        }
    }

    &__people{
        position: relative;
        z-index: 10;
        margin-top: -145px;
        @extend .flex;
        justify-content: flex-end;

        .banner__img{
            border-radius: 15px;
        }
    }

    &__photo img{
        max-width: 100%;
        display: block;

        @include mobile{
            height: auto;
        }
    }

    &__img{
        max-width: 100%;
        display: block;

        @include mobile{
            height: auto;
        }

        &--blog{
            overflow: hidden;
            border-radius: 20px;
        }
    }

    &__img_blog{
        img{
            max-width: 100%;
            display: block;
            overflow: hidden;
            border-radius: 20px;
            height: auto;
            
            @include mobile{
                height: auto;
            }
        }
    }

    &__category{
        margin-bottom: 15px;
        height: 25px;
    }

    &__left--nofound{
        max-width: 395px;

        @include mobile{
            order: 2;
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    &__left--confirmation{
        max-width: 306px;

        @include mobile{
            order: 2;
            max-width: none;
        }
    }
    
    &__right--confirmation{
        @include mobile{
            order: 1;
        }
    }
    
    &__right--nofound{
        max-width: 595px;
        
        @include mobile{
            order: 1;
            margin-top: 35px;
        }
    }

    &__subtitle{
        color: #FFF;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include mobile{
            text-align: center;
            padding-top: 5px;
        }
    }
}

@keyframes animateClients {
    from {
        left: 0px;
    }
    to {
        left: calc(-100% + 1216px);
    }
}

.clients{
    padding-top: 75px;

    @include mobile{
        padding-top: 41px;
    }

    .container{
        height: 125px;
        position: relative;

        @include mobile{
            height: 65px;
        }
    }

    &__scrollbar{
        position: absolute;
    }

    &__list{
        @extend .flex;
        flex-wrap: nowrap;
        column-gap: 115px;
        position: relative;
        animation-name: animateClients;
        animation-duration: 9s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        @include mobile{
            column-gap: 80px;
            animation-duration: 5s;
        }
    }

    &__item{

    }

    &__image{
        @extend .flex;
        align-items: center;
        height: 100%;
        img{
            display: block;

            @include mobile{
                max-width: 80px;
                max-height: 22px;
            }
        }
    }
}

.resources{
    padding-top: 18px;

    &__title{
        color: #001CA6;
        text-align: center;
        font-family: $fontDefault;
        font-size: 32px;
        font-weight: 700;
        line-height: 115%;
        max-width: 940px;
        position: relative;

        @include mobile{
            font-size: 24px;
            line-height: 100%;
        }
    }

    &__panel{
        margin-top: 75px;
        padding: 32px;
        border-radius: 20px;
        border: 1px solid var(--elofy-blue-2018);
        background: var(--cores-software-branco-azulado);
        position: relative;

        @include mobile{
            background: transparent;
            border: 0px none transparent;
            border-radius: 0px;
            margin-top: 54px;
            padding: 0px 2px;
        }
    }

    &__navbar{
        padding-bottom: 8px;
        border-bottom: 1px solid var(--new-magenta-18);

        @include mobile{
            display: none;
        }
    }

    &__tooltip{
        position: absolute;
        z-index: 10;
        display: block;
        width: 24px;
        height: 24px;
        right: 30px;
        top: 44px;
        cursor: pointer;
        @extend .before;
        background-color: var(--cores-software-branco-azulado);

        @include mobile{
            display: none;
        }

        &--left{
            right: auto;
            left:8px;
            transform: rotate(180deg);
            opacity: 0;
            visibility: hidden;

            &.active{
                opacity: 1;
                visibility: visible;
            }
        }

        &::before{
            width: 24px;
            height: 24px;
            @include bgPos(no-repeat, center center, auto auto);
            background-image: url('data:image/svg+xml,<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 12L8.6 10.55L12.15 7H0V5H12.15L8.6 1.45L10 0L16 6L10 12Z" fill="%23001BA6"/></svg>');
        }

        &_text{
            position: absolute;
            left: -20px;
            top: -28px;
            opacity: 0;
            visibility: hidden;
            @extend .trn;
            font-size: 10px;
            height: 25px;
            text-transform: uppercase;
            font-family: $fontTitle;
        }

        &:hover{
            .resources__tooltip_text{
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__nav{
        @extend .flex;
        flex-wrap: nowrap;
        column-gap: 40px;
        position: relative;
    }
    
    &__nav_item{

    }

    &__nav_link{
        display: block;
        padding: 11px 8px 8px 8px;
        color: var(--cores-software-cinza); 
        font-size: 16px;
        white-space: nowrap;
        font-weight: 500;
        line-height: 150%;
        cursor: pointer;
        @extend .trn;

        &:hover, &:active{
            color: #E86BF7;
        }

        &--active, &.active{
            color: var(--cores-da-marca-elofy-blue-20) !important;
        }
    }
    
    &__content{
        height: 300px;
        position: relative;
        margin-top: 23px;

        @include mobile{
            height: auto;
            margin-top: 0px;

            .slick-arrow {
                display: inline-block !important;
                position: absolute;
                text-indent: 101%;
                white-space: nowrap;
                overflow: hidden;
                width: 24px;
                height: 24px;
                top: 60px;
                background-repeat: no-repeat;
                background-position: center center;
                z-index: 50;
            }

            .slick-prev {
                background-image: url('data:image/svg+xml,<svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.55002 8L10.6833 14.1333L8.81668 16L0.816684 8L8.81668 -1.63189e-07L10.6834 1.86667L4.55002 8Z" fill="%23B3AFAF"/></svg>');
                left: -6px;
            }

            .slick-next {
                background-image: url('data:image/svg+xml,<svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.44998 8L0.31665 1.86667L2.18332 0L10.1833 8L2.18332 16L0.31665 14.1333L6.44998 8Z" fill="%23B3AFAF"/></svg>');
                right: -6px;
            }
        }
    }

    &__item{
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        @extend .flex;
        justify-content: space-between;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        @extend .trn;
        &--active, &.active{
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }

        @include mobile{
            height: auto;
            justify-content: flex-start;
            left: 0;
            opacity: 1;
            position: relative;
            top: 0;
            visibility: visible;
            width: 100%;
            z-index: 1;
        }
    }

    &__data, &__picture{
        max-width: 540px;
    }

    &__data{
        padding: 16px 24px 0px 24px;
        
        @include mobile{
            order: 2;
            padding: 16px 0px 0;
        }
    }

    &__picture{
        @extend .flex;
        justify-content: flex-end;
        padding-top: 22px;

        @include mobile{
            order: 1;
            padding-top: 0px;
        }
    }

    &__icon{
        @extend .flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--elofy-blue-2018); 

        @include mobile{
            height: 24px;
            width: 24px;
            img{
                width: 12px;
            }
        }
    }

    &__title{
        @extend .flex;
        align-items: center;
        column-gap: 17px;
        margin-bottom: 16px;
    }

    &__text, &__text p{
        color: var(--cores-software-cinza-azulado);
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;

        @include mobile{
            font-size: 14px;
            line-height: 143%;
        }
    }

    &__text p{
        margin-bottom: 15px;
    }

    &__more{
        padding-top: 8px;
    }

    &__image{
        @include mobile{
            margin-left: 18px;
            margin-right: 18px;
        }

        img{
            max-width: 100%;
            
            @include mobile{
                height: auto;
            }
        }
    }
}

.numbers{
    padding-top: 80px;
    padding-bottom: 110px;

    @include mobile{
        padding-bottom: 70px;
        padding-top: 40px;
    }

    &__content{
        display: flex;
        justify-content: space-between;

        @include mobile{
            justify-content: flex-start;
            overflow-x: scroll;
            column-gap: 25px;
        }
    }

    &__item{
        padding:0px 30px 0px 30px;

        @include mobile{
            padding: 0 0px;
            flex: 0 0 auto;
            max-width: 150px;
        }
    }
    
}

.number{
    &__value, &__text{
        display: block;
        text-align: center;
        line-height: 133%;
    }

    &__value{
        color: #E86BF7;
        font-family: $fontTitle;
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 12px;

        @include mobile{
            font-size: 24px;
            margin-bottom: 0px;
            text-transform: uppercase;
        }
    }

    &__text{
        color: var(--cores-software-cinza-azulado);
        font-size: 24px;
        font-weight: 400;

        @include mobile{
            font-size: 14px;
            font-weight: 400;

            br{
                display: none;
            }
        }
    }
}

.study{
    padding-top: 120px;
    padding-bottom: 110px;
    background-color: var(--cores-da-marca-elofy-blue-20); 

    @include mobile{
        padding-bottom: 40px;
        padding-top: 40px;

        .container{
            padding-left: 23px;
            padding-right: 23px;
        }
    }

    &__title{
        color: #fff;
        font-size: 40px;
        font-weight: 500;
        line-height: 83%;
        text-transform: uppercase;
        margin-bottom: 40px;

        @include mobile{
            font-size: 24px;
        }
    }

    &__text, &__text p{
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        line-height: 133%;
        
        @include mobile{
            font-size: 14px;
            line-height: 143%;
        }
    }

    &__text{
        max-width: 1005px;

        @include mobile{
            padding-right: 10px;
        }
    }

    &__more{
        padding-top: 40px;

        @include mobile{
            padding-top: 27px;
        }
    }

    &__btn{
        font-weight: 600;
        font-size: 16px;
        color: var(--cores-da-marca-elofy-blue-20); 
        background: var(--cores-da-marca-amarelofy); 
        height: 40px;
        padding-left: 26px;
        padding-right: 32px;
        border-radius: 20px;
        border-top-left-radius: 0px;
        
        @include mobile{
            font-size: 10px;
            height: 25px;
            padding-left: 16px;
            padding-right: 16px;
            border-radius: 20px;
        }
    }
}

.blog_home{
    padding-top: 112px;
    padding-bottom: 60px;

    &__header{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 72px;
        padding-top: 112px;

        @include mobile{
            padding-top: 35px;
            grid-template-columns: 2fr 1fr;
            margin-bottom: 24px;
        }
    }

    &__more{
        text-align: right;
        padding-top: 28px;

        @include mobile{
            padding-top: 9px;
        }

        &_link{
            color: var(--blog-magenta);
            font-size: 16px;
            font-weight: 500;

            @include mobile{
                color: var(--cores-da-marca-elofy-blue-20);
                font-size: 8px;
            }
        }
    }
}

.blog__loader{
    text-align: center;
    padding-top: 60px;
}

.integracoes{
    margin-top: 54px;
    margin-bottom: 115px;

    @include mobile{
        margin-bottom: 32px;
    }

    &__content{
        padding: 70px 215px;
        background-color: var(--cores-da-marca-elofy-blue-20);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 30px;

        @include mobile{
            border-radius: 8px;
            padding: 24px 21px;
            column-gap: 30px;
        }

        &--alt{
            @include mobile{
                background-color: var(--escala-de-cinzas-cinza-5);
                border:1px solid var(--escala-de-cinzas-cinza-2);
            }
        }
    }

    &__title{
        @extend .flex;
        align-items: center;
        column-gap: 12px;
        margin-bottom: 22px;

        @include mobile{
            column-gap: 10px;
            margin-bottom: 4px;

            img{
                width: 16px;
            }
        }

        h3{
            color: #fff;
            font-size: 40px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase; 
            padding-top: 2px;

            @include mobile{
                font-size: 16px;
            }
        }

        &--alt{
            @include mobile{
                h3{
                    color: var(--cores-da-marca-new-magenta);
                }
            }
        }
    }

    &__text{
        padding-left: 52px;
        max-width: 380px;

        @include mobile{
            padding-left: 26px;
        }
    }

    &__text, &__text p{
        color: var(--escala-de-cinzas-cinza-4);
        font-size: 24px;
        font-weight: 500;
        line-height: 133%;

        @include mobile{
            font-size: 8px;
            line-height: 113%;
        }
    }
    
    &__text--alt, &__text--alt p{
        @include mobile{
            color: var(--cores-software-cinza-azulado); 
        }
    }

    &__list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 25px;
        row-gap: 16px;

        @include mobile{
            column-gap: 8px;
            row-gap: 8px;
        }
    }

    &__item{
        background-color: #fff;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;

        &--alt{
            @include mobile{
                border: 1px solid var(--cores-software-cinza-claro);
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
            }
        }

        @include mobile{
            height: 22px;
            width: 22px;

            img{
                max-width: 60%;
                max-height: 60%;
            }
        }
    }
}

.newsletter{
    padding: 88px 0px 88px 0px;
    background-color: var(--cores-software-branco-azulado);

    @include mobile{
        padding: 24px 8px;
        background-color: var(--cores-da-marca-elofy-blue-20);
    }

    &--solution{
        margin-bottom: 55px;
        
        @include mobile{
            margin-bottom: 10px;
            background-color: var(--cores-software-branco-azulado);
        }
        
        .newsletter__text, .newsletter__text p{
            @include mobile{
                color: var(--cores-da-marca-elofy-blue-20);
            }
        }

        .form__input{
            @include mobile{
                color: var(--cores-da-marca-elofy-blue-20);
                border: 1px solid var(--cores-da-marca-elofy-blue-20);
            }
        }
    }

    &__content{
        @extend .flex;
        justify-content: space-between;
    }

    &__text{
        max-width: 320px;
    }

    &__text, &__text p{
        color: var(--cores-da-marca-elofy-blue-20);
        font-size: 24px;
        font-weight: 600;
        line-height: 133%;

        @include mobile{
            color: #fff;
            font-size: 16px;
            line-height: 100%;
            text-align: center;
        }
    }

    &__confirmation{
        margin-bottom: 15px;
        margin-left: 2px;
    }

    &__confirmation, &__confirmation p{
        color: var(--cores-da-marca-elofy-blue-20);
        font-size: 14px;
        font-weight: 500;

        @include mobile{
            color: #fff;
        }
    }

    &__formulary{
        width: 100%;
        max-width: 566px;

        @include mobile{
            margin-top: 16px;
        }
    }
}

.main{
    &--solution{
        .newsletter{
            margin-bottom: 55px;
            
            @include mobile{
                margin-bottom: 10px;
                background-color: var(--cores-software-branco-azulado);
            }
            
            .newsletter__text, .newsletter__text p{
                @include mobile{
                    color: var(--cores-da-marca-elofy-blue-20);
                }
            }
    
            .form__input{
                @include mobile{
                    color: var(--cores-da-marca-elofy-blue-20);
                    border: 1px solid var(--cores-da-marca-elofy-blue-20);
                }
            }
        }
    }
}

.presentation{
    padding-top: 80px;
    padding-bottom: 36px;

    @include mobile{
        padding-bottom: 24px;
        padding-top: 33px;
    }
    
    &__content{
        padding: 47px 0px;
        background-color: var(--new-magenta-18); 
        border-radius: 20px; 
        @extend .flex;
        justify-content: center;

        @include mobile{
            padding: 40px 30px;
        }
    }

    &__text{
        max-width: 990px;

        p{
            color: var(--cores-da-marca-elofy-blue-20);
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
        }
    }
}

.values{
    padding-top: 36px;
    padding-bottom: 90px;

    @include mobile{
        padding-bottom: 30px;
    }

    &__title{
        color: var(--cores-da-marca-new-magenta); 
        font-size: 24px;
        font-weight: 700;
        line-height: 133%;
        text-transform: uppercase;

        @include mobile{
            font-family: $fontTitle;
            color: var(--cores-da-marca-new-magenta);
            font-size: 24px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    &__arrow{
        float: right;
        color: var(--cores-da-marca-new-magenta);
        font-family: $fontDefault;
        font-size: 24px;
        font-weight: 600;
        
        @include mobile{
            display: none;
        }
    }

    &__scrollbar{
        margin-top: 58px;
        overflow: hidden;

        @include mobile{
            margin-top: 43px;
        }
    }

    &__list{
        display: flex;
        column-gap: 64px;

        @include mobile{
            column-gap: 16px;
        }
    }

    &__item{
        @include flex(312px);

        @include mobile{
            @include flex(210px);
        }
    }
}

.value{
    &__title{
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        column-gap: 12px;

        @include mobile{
            margin-bottom: 4px;
        }

        .title__value{
            @include mobile{
                font-family: $fontTitle !important;
                color: var(--cores-da-marca-elofy-blue-20);
                font-size: 24px;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
            }
        }

        svg{
            @include mobile{
                width: 24px;
            }
        }
    }
}

.history{
    padding-top: 36px;
    padding-bottom: 74px;

    @include mobile{
        padding-bottom: 0px;

        .container {
            padding: 0px;
        }
    }

    &__content{
        border-radius: 20px;
        background-color: var(--new-magenta-18); 
        padding: 75px 100px 64px 100px;

        @include mobile{
            background-color: #F7F8FC;
            border-radius: 0px;
            padding: 55px 23px 90px;
        }
    }

    &__title{
        color: var(--cores-da-marca-new-magenta, #E66CF7);
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        line-height: 96%;
        text-transform: uppercase; 
        margin-bottom: 10px;

        @include mobile{
            font-size: 24px;
            margin-bottom: 20px;
        }
    }

    &__text{
        max-width: 560px;
    }

    &__text, &__text p{
        color: var(--escala-de-cinzas-cinza-1);
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;

        @include mobile{
            color: var(--cores-da-marca-elofy-blue-20);
        }
    }

    &__nav{
        padding-top: 47px;
        margin-bottom: 65px;

        @include mobile{
            margin-bottom: 30px;
        }
    }

    &__menu{
        @extend .flex;
        justify-content: center;
        column-gap: 76px;
        margin-bottom: 18px;

        @include mobile{
            column-gap: 14px;
            margin-bottom: 25px;
            margin-left: -10px;
            margin-right: -10px;
            width: calc(100% + 20px);
        }
    }

    &__btn{
        padding: 0px 10px;
        color: var(--escala-de-cinzas-cinza-1);
        text-align: center;
        font-family: $fontDefault;
        font-size: 24px;
        font-weight: 600;
        line-height: 133%;
        @extend .trn;

        &:hover, &:active{
            color: var(--cores-da-marca-new-magenta); 
        }

        @include mobile{
            font-size: 16px;
            font-weight: 500;
            padding: 0 4px;
            color: var(--escala-de-cinzas-cinza-2); 
        }

        &.active{
            color: var(--cores-da-marca-new-magenta);
        }
    }

    &__bar{
        background-color: #fff;
        height: 4px;
        
        @include mobile{
            width: 284px !important;
        }
    }

    &__timebar{
        background-color: var(--cores-da-marca-new-magenta);
        width: 0px;
        height: 4px;
        @extend .after;
        position: relative;

        &::after{
            left: auto;
            right: -2px;
            top: -2px;
            width: 8px;
            height: 8px;
            background-color: var(--cores-da-marca-new-magenta);
            border-radius: 50%;
        }
    }

}

.timeline{
    height: 300px;
    position: relative;

    @include mobile{
        height: auto;
    }

    &__item{
        @extend .flex;
        @extend .trn;
        column-gap: 55px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        z-index: -1;
        width: 100%;
        justify-content: center;
        left: 0px;
        top: 0px;
        
        @include mobile{
            position: relative;
            display: none;
        }

        &--active, &.active{
            opacity: 1;
            visibility: visible;
            z-index: 5;
            
            @include mobile{
                display: block;
            }
        }
    }

    &__image{
        @include flex(414px);
        height: 272px;
        border-radius: 20px; 
        overflow: hidden;

        @include mobile{
            flex: 0 0 100%;
            height: 212px;
            max-width: 100%;
            width: auto;
            margin-bottom: 30px;
            height: auto;
        }

        img{
            @extend .imgCover;
        }
    }

    &__data{
        @include flex(308px);

    }

    &__title{
        color: var(--cores-da-marca-new-magenta);
        font-family: $fontDefault;
        font-size: 24px;
        font-weight: 600;
        line-height: 133%;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--escala-de-cinzas-cinza-5);

        @include mobile{
            color: var(--cores-da-marca-new-magenta);
            font-family: $fontTitle;
            font-size: 24px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            border-bottom: 1px solid var(--escala-de-cinzas-cinza-3);
            padding-bottom: 8px;
            margin-bottom: 10px;
        }
    }

    &__text{

        li{
            color: var(--cores-software-cinza);
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            padding-left: 10px;
            position: relative;
            @extend .before;

            @include mobile{
                color: var(--cores-da-marca-elofy-blue-20);
            }
            
            &::before{
                background-color: var(--cores-software-cinza);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                top: 11px;
                left: 0px;
                
                @include mobile{
                    background-color: var(--cores-da-marca-elofy-blue-20);
                }
            }
        }
    }
}

.founders{
    padding-top: 72px;
    padding-bottom: 50px;
    position: relative;
    z-index: 5;

    @include mobile{
        padding-top: 45px;
    }

    &__sectitle{
        @include mobile{
            font-family: $fontTitle;
            color: var(--cores-da-marca-new-magenta);
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    &__list{
        @extend .flex;
        justify-content: space-between;
        margin-top: 32px;

        @include mobile{
            margin-top: 43px;
            row-gap: 32px;
        }
    }

    &__item{
        @include mobile{
            @include flex(100%);
            display: flex;
            justify-content: center;
        }
    }
}

.founder{
    &__image{
        width: 240px;
        height: 240px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 28px;

        img{
            @extend .imgCover;
        }
    }

    &__title{
        color: var(--footer-magenta);
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase; 
        margin-bottom: 3px;
    }

    &__job{
        
    }

    &__job, &__job p{
        color: var(--cores-software-cinza-azulado);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
}

.testimonials{
    padding-top: 55px;
    margin-bottom: 75px;

    &__content{
        padding-top: 72px;
        border-top: 1px solid var(--escala-de-cinzas-cinza-3);

        @include mobile{
            border-top: 0px none transparent;
            padding-top: 0px;
        }
    }

    &__list{
        @extend .flex;
        flex-wrap: nowrap;
        column-gap: 32px;

        @include mobile{
            row-gap: 32px;
            cursor: initial !important;
        }
    }

    &__item{
        @include flex(728px);
        @extend .flex;

        @include mobile{
            @include flex(100%);
        }
    }
}

.testimonial{
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid var(--escala-de-cinzas-cinza-3);
    display: grid;
    grid-template-columns: 58% 42%;
    height: $testimonialHeight;

    @include mobile{
        grid-template-columns: 1fr;
        height: auto;
        border: none !important;
    }

    &__image{
        position: relative;

        @include mobile{
            order: 1;
            height: 185px;

            img{

            }
        }

        img{
            @extend .imgCover;
        }
    }

    &__data{
        padding: 68px 28px 28px 48px;

        @include mobile{
            order: 2;
            padding: 20px 20px 20px 20px;
            border: 1px solid var(--escala-de-cinzas-cinza-3);
            border-top: 0px none !important;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }

    &__text, &__text p{
        color: var(--cores-software-cinza-azulado);
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
    }

    &__name{
        margin-top: 32px;
        color: var(--footer-magenta);
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;

        @include mobile{
            margin-top: 15px;
        }
    }

    &__job{
        color: var(--cores-software-cinza);
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
    }
}
.solution{

    &__main{
        padding: 48px 72px;
        margin-bottom: 52px;
        margin-top: 20px;
        border-radius: 20px;
        background: var(--cores-da-marca-elofy-blue-20);
        position: relative;

        @include mobile{
            margin-top: 10px;
            padding: 32px 24px;
        }
    }

    &__item{
        @extend .flex;
        justify-content: space-between;
        //align-items: center;
    }

    &__data{
        @include flex(360px);

        @include mobile{
            @include flex(100%);
            order: 2;
        }
    }

    &__screenshot{
        padding-top: 48px;
        @include flex(610px);

        @include mobile{
            padding-top: 0px;
            @include flex(100%);
            order: 1;
        }

        img {
            max-width: 100%;
            height: auto;
            
            @include mobile{
                height: auto;
            }
        }

        img{
            @include mobile{
                max-width: 100%;
                height: auto;
            }
        }
    }

    &__image{
        display: flex;
        justify-content: flex-end;

        @include mobile{
            justify-content: center;
        }
    }

    &__title{
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        line-height: 96%;
        text-transform: uppercase;
        margin-bottom: 16px;
        padding-top: 40px;

        @include mobile{
            font-size: 32px;
            line-height: 90%;
            margin-bottom: 12px;
            padding-top: 24px;
        }
    }

    &__text, &__text p{
        color: var(--escala-de-cinzas-cinza-4);
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;

        @include mobile{
            line-height: 111%;
        }
    }

    &__accordion {
        padding-top: 12px;
    }

    &__action{
        width: 100%;
        color: var(--escala-de-cinzas-cinza-5);
        font-family: $fontDefault;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        display: flex;
        align-items: center;
        column-gap: 14px;
        padding-left: 3px;
        margin-top: 25px;
        background-image: url('data:image/svg+xml,<svg width="9" height="4" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 4L0.5 0H8.5L4.5 4Z" fill="%23FFFAFA"/></svg>');
        background-repeat: no-repeat;
        background-position: calc(100% - 35px) center;
        padding-right: 50px;

        @include mobile{
            font-size: 14px;
            padding-right: 20px;
            background-position: calc(100% - 0px);
            column-gap: 8px;
            padding-left: 0px;
            margin-top: 22px;
        }

        svg {
            min-width: 25px; 
        }
    }

    &__display{
        margin-top: 12px;
        margin-left: 5px;
        margin-right: 25px;
        display: none;
        padding-top: 10px;
        border-top: 1px solid #BFC4CD;

        p{
            color: var(--escala-de-cinzas-cinza-4);
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;

            @include mobile{
                font-size: 14px;
            }
        }
    }

    &__cta{
        padding-top: 35px;

        .btn--agendar{
            @include mobile{
                font-family: $fontTitle;
                height: 29px;
                width: 100%;
                text-transform: uppercase;
                font-size: 12px;
            }
        }
    }

    &__nav{
        position: absolute;
        right: 72px;
        top: 56px;

        @include mobile{
            display: none;
        }

        &_btn{
            height: 24px;
            width: 24px;
            border: 1px solid rgba(255,255,255,0.5);
            border-radius: 6px;
            @extend .iconHover;
            @extend .trn;

            &:hover{
                border-color: rgba(255,255,255,1);
            }

            &--prev::before{
                background-image:url('data:image/svg+xml,<svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.70059 8.18022L0.100586 4.58022L3.70059 0.980225L4.54059 1.82022L1.78059 4.58022L4.54059 7.34023L3.70059 8.18022Z" fill="white" fill-opacity="0.5"/></svg>');
            }
            &--prev::after{
                background-image:url('data:image/svg+xml,<svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.70059 8.18022L0.100586 4.58022L3.70059 0.980225L4.54059 1.82022L1.78059 4.58022L4.54059 7.34023L3.70059 8.18022Z" fill="white" fill-opacity="1"/></svg>');
            }
            &--next::before{
                background-image:url('data:image/svg+xml,<svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" d="M0.940586 8.18022L0.100586 7.34023L2.86059 4.58022L0.100586 1.82022L0.940586 0.980225L4.54059 4.58022L0.940586 8.18022Z" fill="white"/></svg>');
            }
            &--next::after{
                background-image:url('data:image/svg+xml,<svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="1" d="M0.940586 8.18022L0.100586 7.34023L2.86059 4.58022L0.100586 1.82022L0.940586 0.980225L4.54059 4.58022L0.940586 8.18022Z" fill="white"/></svg>');
            }
        }
    }

    &__resources{
        padding-top: 52px;
        padding-bottom: 104px;
        margin-bottom: 52px;
        border-bottom:1px solid #D9D9D9;

        @include mobile{
            padding-top: 35px;
            padding-bottom: 35px;
            margin-bottom: 0px;
        }
    }

    &__modules{
        margin-top: 48px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 32px;
        //row-gap: 40px;

        @include mobile{
            margin-top: 30px;
            column-gap: 0px;
            grid-template-columns: 1fr;
        }
    }

    &__module {
        padding-bottom: 44px;
        max-height: 100px;

        @include mobile{
            max-height: 96px;
            padding-bottom: 40px;
        }
    }

    .slick-dots{
        @include mobile{
            display: flex;
            justify-content: center;
            position: absolute;
            width: 100%;
            bottom: -65px;
            column-gap: 5px;
        }

        li button{
            @include mobile{
                text-indent: 101%;
                white-space: nowrap;
                overflow: hidden;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #FBE0FB;
            }
        }
        li.slick-active button{
            @include mobile{
                background-color: var(--cores-da-marca-new-magenta);
            }
        }
    }
}

.module{
    @extend .flex;
    column-gap: 18px;
    flex-wrap: nowrap;

    @include mobile{
        //column-gap: 8px;
    }

    &__icon{
        @include flex(56px);
        @extend .flex;
        height: 56px;
        background-color: var(--elofy-blue-2018);
        justify-content: center;
        align-items: center;
    }

    &__text {
        //padding-top: 4px;
        align-items: center;
        display: flex;
    }

    &__text, &__text p{
        color: var(--escala-de-cinzas-cinza-1);
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
    }
}

.post{
    position: relative;
    z-index: 10;
    padding-top: 64px;
    padding-bottom: 40px;

    @include mobile{
        padding-top: 16px;
    }

    &__category_flag{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--cores-da-marca-new-magenta);
        font-family: $fontTitle;
        font-size: 16px;
        font-weight: 400;
        line-height: 87%;
        height: 25px;
        padding: 1px 24px 0px 24px;
        border-radius: 32px;
        background: var(--new-magenta-18);
        border-radius: 20px;
        text-transform: uppercase;

        @include mobile{
            font-size: 8px;
            height: 19px;
        }
    }

    &__date_author{
        margin-top: 32px;
        column-gap: 32px;
        @extend .flex;

        @include mobile{
            margin-top: 16px;
        }
    }

    &__date, &__author{
        @extend .flex;
        column-gap: 8px;
        align-items: center;
        width: auto;

        span{
            color: var(--cores-da-marca-elofy-blue-20);
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            
            @include mobile{
                font-size: 8px;
            }
        }

        svg{
            @include mobile{
                height: 12px;
            }
        }
    }

    &__title{
        color: var(--cores-da-marca-new-magenta);
        font-size: 64px;
        font-weight: 700;
        line-height: 88%;
        text-transform: uppercase;
        margin-top: 32px;
        margin-bottom: 32px;

        @include mobile{
            font-size: 32px;
            line-height: 100%;
            margin-top: 28px;
        }
    }

    &__photo{
        img{
            max-width: 100%;
            display: block;
            border-radius: 20px;
            height: auto;
            
            @include mobile{
                height: auto;
            }
        }
    }

    &__main{
        padding-top: 58px;
        position: relative;
        @extend .flex;
        justify-content: space-between;

        @include mobile{
            padding-top: 18px;
        }
    }

    &__content{
        @include flex(calc(100% - 490px));

        @include mobile{
            @include flex(100%);
        }

        p, li{
            color: var(--cores-software-cinza);
            font-size: 16px;
            font-weight: 400;
            line-height: 200%;

            @include mobile{
                color: var(--cores-software-cinza-azulado);
                line-height: 150%;
            }

            a{
                color: var(--cores-da-marca-new-magenta); 
                text-decoration: underline;
            }
        }

        h1, h2, h3, h4, h5, h6{
            font-family: $fontDefault;
        }

        h1:not(.post__content_title), h2:not(.post__content_title), h3:not(.post__content_title), h4{
            color: var(--cores-da-marca-elofy-blue-20);
            font-size: 24px;
            font-weight: 600;
            line-height: 133%;
            margin-bottom: 3px;

            @include mobile{
                font-size: 16px;
            }
        }

        h5{
            color: var(--cores-software-cinza);
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 8px;
            
            @include mobile{
                color: var(--cores-software-cinza-azulado);
            }
        }
    }

    &__resume_title{
        @extend .flex;
        align-items: center;
        column-gap: 8px;
        margin-bottom: 24px;

        span{
            color: var(--cores-da-marca-elofy-blue-20);
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;

            @include mobile{
                font-size: 14px;
            }
        }
    }

    &__content_title{
        color: var(--cores-da-marca-new-magenta);
        font-size: 24px;
        font-weight: 600;
        line-height: 133%;
        margin-top: 26px;
        margin-bottom: 26px;

        @include mobile{
            font-size: 16px;
            margin-bottom: 5px;
            margin-top: 22px;
        }
    }

    &__conclusion, hr{
        border-top: 1px solid #D9D9D9;
        margin-top: 24px;
        margin-bottom: 24px;
        border-bottom: 0px none transparent;
    }

    &__text p, &__resume p{
        margin-bottom: 30px;
    }
    
    &__anchors nav ul{
        margin-left: 0px !important;
        li{
            line-height: 154% !important;
            padding-left: 20px !important;
            margin-bottom: 2px !important;
            position: relative;
            @extend .before;
    
            @include mobile{
                line-height: 120% !important;
            }
            
            &::before{
                background-color: #000;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                top: 9px;
                left: 6px;
    
                @include mobile{
                    top: 7px;
                }
            }
            a{
                color: #000 !important;
                font-size: 14px;
                font-weight: 400;
    
                @include mobile{
                    font-size: 10px;
                }
            }
            ul{
                margin-left: -20px !important;
            }
        }
    } 

}

.post_related{
    background: var(--elofy-blue-2018);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    padding-top: 70px;
    padding-bottom: 63px;
    align-items: center;

    @include mobile{
        padding-bottom: 30px;
        padding-top: 35px;
    }

    &__header{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 32px;

        @include mobile{
            margin-bottom: 22px;
        }
    }

    &__title{
        color: var(--cores-software-cinza-azulado);
        font-family: $fontDefault;
        font-size: 24px;
        font-weight: 500;
        line-height: 100%;
        
        @include mobile{
            font-family: $fontTitle;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    &__more{
        justify-content: flex-end;
        align-items: center;
        display: flex;
        a{
            color: #1B1B1B;
            font-size: 16px;
            font-weight: 500;
            line-height: 100%;

            @include mobile{
                font-size: 8px;
                color: var(--cores-da-marca-elofy-blue-20);
            }
        }
    }
}

.sidebar{
    @include flex(416px);

    @include mobile{
        @include flex(100%);
    }

    &__nav{
        position: sticky;
        top: calc($headerHeight + 20px);
        padding: 24px 16px 22px 16px;
        border-radius: 20px;
        background: var(--new-magenta-18);

        @include mobile{
            position: relative;
            top: 0px;
            margin-top: 22px;
        }
    }

    &__title{
        color: var(--cores-da-marca-elofy-blue-20);
        font-size: 24px;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 16px;
        padding-left: 16px;
        font-family: $fontTitle !important;

        @include mobile{
            font-size: 16px !important;
            margin-bottom: 10px !important;
        }
    }
}

.anchors{
    .anchor{
        line-height: 154%;
        padding-left: 20px;
        margin-bottom: 2px;
        position: relative;
        @extend .before;

        @include mobile{
            line-height: 120%;
        }
        
        &::before{
            background-color: #000;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            top: 9px;
            left: 6px;

            @include mobile{
                top: 7px;
            }
        }
        a{
            color: #000;
            font-size: 14px;
            font-weight: 400;

            @include mobile{
                font-size: 10px;
            }
        }
    }
}

.policy{

    @include mobile{
        position: relative;
        z-index: 10;
    }

    &__intro{
        padding-top: 72px;
        padding-bottom: 88px;

        @include mobile{
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .container{
            max-width: 1056px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include mobile{
                display: block;
            }
        }

        &_img{
            border-radius: 20px;
            display: block;

            @include mobile{
                max-width: 270px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &_left{
            max-width: 416px;
            position: relative;
        }

        &_right{
            max-width: 488px;

            @include mobile{
                padding-top: 24px;
            }

            p{
                color: #0021AA;
                font-size: 16px;
                font-weight: 500;
                line-height: 200%;

                @include mobile{
                    line-height: 150%;
                }
            }
        }
    }

    &__about{
        padding-top: 62px;
        padding-bottom: 62px;
        background-color: var(--cores-da-marca-new-magenta);

        @include mobile{
            padding-bottom: 35px;
            padding-top: 35px;
        }

        &_text{
            margin-top: 15px;

            p, h1, h2, h3, h4, h5, h6{
                color: #fff;
                font-family: $fontDefault;
                font-size: 16px;
                line-height: 200%;
            }
            h4{
                font-weight: 600;
                margin-bottom: 15px;
            }
            p{
                font-weight: 500;
            }
        }
    }

    &__content{
        padding-top: 64px;
        padding-bottom: 35px;

        h1, h2, h3, h4, h5, h6{
            color: #0021AA;
            font-size: 32px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;

            @include mobile{
                font-size: 24px;
            }
        }

        h2{
            font-size: 40px;
        }

        h3{
            margin-bottom: 20px;
        }

        p{
            color: #292A2B;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
        }

        hr{
            border-top: 1px solid var(--escala-de-cinzas-cinza-3);
            margin-top: 64px;
            margin-bottom: 64px;
            
            @include mobile{
                margin-bottom: 32px;
                margin-top: 32px;
            }
        }

        p span{
            color: #E86BF7;
            font-family: $fontTitle;
            font-size: 20px;
            font-weight: 500;
            line-height: 120%;
            text-transform: uppercase;
        }

        a{
            color: var(--cores-da-marca-new-magenta);
            font-weight: 500;
        }
    }

    &__secondary{
        padding-top: 130px;

        @include mobile{
            padding-top: 50px;
        }

        h1, h2, h3, h4, h5, h6{
            color: #0021AA;
            font-size: 32px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;

            @include mobile{
                font-size: 24px;
            }
        }

        h2{
            font-size: 40px;
            margin-bottom: 24px;

            @include mobile{
                font-size: 24px;
            }
        }

        h3{
            margin-bottom: 18px;
        }

        h4{
            color: #E86BF7;
            font-family: $fontDefault;
            font-size: 24px;
            font-weight: 600;
            line-height: 133%;
            text-transform: none;
            margin-bottom: 18px;

            @include mobile{
                font-size: 16px;
            }
        }

        p{
            color: var(--cores-software-cinza); 
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;

            @include mobile{
                color: var(--cores-software-cinza-azulado); 
            }
        }

        hr{
            border-top: 1px solid var(--escala-de-cinzas-cinza-3);
            margin-top: 64px;
            margin-bottom: 64px;

            @include mobile{
                margin-top: 32px;
                margin-bottom: 32px;
            }
        }

        p span{
            color: #E86BF7;
            font-family: $fontTitle;
            font-size: 20px;
            font-weight: 500;
            line-height: 120%;
            text-transform: uppercase;
        }

        ul{
            padding: 36px 52px;
            margin-top: 18px;
            margin-bottom: 18px;
            border-radius: 20px;
            background-color: var(--escala-de-cinzas-cinza-4);

            @include mobile{
                padding: 20px 10px 20px 10px;
            }
        }

        li{
            color: var(--cores-software-cinza);
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            position: relative;
            padding-left: 25px;
            @extend .before;
            
            @include mobile{
                color: var(--cores-software-cinza-azulado); 
            }
            
            &::before{
                background-color: var(--cores-software-cinza);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                top: 11px;
                left: 10px;
                
                @include mobile{
                    background-color: var(--cores-software-cinza-azulado); 
                }
            }
        }
        
        a{
            color: var(--cores-da-marca-new-magenta);
            font-weight: 500;
        }
        
        &--alt{
            padding-top: 278px;

            @include mobile{
                padding-top: 40px;
            }

            h3 {
                margin-bottom: 25px;
            }

            h4 {
                margin-bottom: 20px;
            }

            p span {
                font-size: 24px;
                font-weight: 700;
            }
        }
    }

    &__data{
        @extend .flex;
        justify-content: space-between;

        &--alt{
            padding-top: 215px;
            padding-bottom: 35px;

            @include mobile{
                padding-top: 45px;
            }
            
            p{
                line-height: 200%;
            }
            
            h3{
                color: var(--cores-da-marca-new-magenta);
                margin-bottom: 30px;
            }
        }

        &--col{
            h3{
                color: var(--cores-da-marca-new-magenta);
            }
        }

        &_left{
            @include flex(584px);
            padding-top: 15px;

            @include mobile{
                padding-top: 45px;
                order: 2;
                @include flex(100%);
            }

            &--alt{
                @include flex(606px);

                @include mobile{
                    @include flex(100%);
                }
            }
            
            &--col{
                @include flex(485px);
                
                @include mobile{
                    @include flex(100%);
                }
            }
        }

        &_right{
            @include flex(520px);
            position: relative;
            
            @include mobile{
                @include flex(100%);
                order: 1;
            }

            &--alt{
                @include flex(472px);
                padding-right: 60px;
                
                @include mobile{
                    @include flex(100%);
                    padding-right: 0px;
                    padding-left: 20px;
                }
            }

            &--col{
                @include flex(485px);
                
                @include mobile{
                    @include flex(100%);
                }
            }

            figure{
                img{
                    display: block;
                    border-radius: 20px;
                    
                    @include mobile{
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

.nofound{
    background: var(--cores-da-marca-elofy-blue-20);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);

    @include mobile{
        position: relative;
    }

    .container--nofound{
        padding-top: 134px;
        padding-bottom: 170px;
        
        @include mobile{
            padding-top: 0px;
            padding-bottom: 90px;
        }
    }

    &__text{
        padding-top: 10px;
        max-width: 310px;

        @include mobile{
            margin-left: auto;
            margin-right: auto;
        }

        p{
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            line-height: 133%;

            @include mobile{
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                text-align: center;
            }
        }
    }

    &__cta{
        padding-top: 30px;
        padding-bottom: 5px;
        
        @include mobile{
            text-align: center;
        }
    }

    .banner__content{
        align-items: flex-end;
    }

    &__graphic{
        @include mobile{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
        }
    }
}

.confirmation{
    background: var(--cores-da-marca-elofy-blue-20);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    
    &__text{
        padding-top: 24px;

        @include mobile{
            padding-top: 18px;
            padding-left: 18px;
            padding-right: 18px;
        }

        p{
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            
            @include mobile{
                text-align: center;
            }
        }
    }

    .container--confirmation{
        padding-top: 126px;
        padding-bottom: 142px;

        @include mobile{
            padding-bottom: 55px;
            padding-top: 4px;
        }
    }
}

// testes
.resources__nav, .values__list, .testimonials__list{
    overflow-x: scroll;
    padding-bottom: 30px;
}
.values__list, .testimonials__list{
    margin-bottom: -30px;
    cursor: grab;
}
.resources__navbar_scroll, .values__scrollbar{
    overflow: hidden;
    margin-bottom: -30px;
}
.resources__navbar_scroll {
    position: relative;
    margin-right: 35px;
}
.resources__navbar_mask{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: calc(100% - 30px);
    z-index: 99;
    display: none;
}
.slick-arrow {
    display: none !important;
}

.burger__btn{
    width: 28px;
    height: 26px;
    margin-right: 8px;
    padding-top: 1px;
    @include bgPos(no-repeat, left center, auto auto);
    background-image: url('data:image/svg+xml,<svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_4353_28710" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="17"><rect y="0.5" width="20.2667" height="16" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4353_28710)"><path d="M2.53333 13.5667V11.8778H12.6667V13.5667H2.53333ZM2.53333 9.34446V7.65557H17.7333V9.34446H2.53333ZM2.53333 5.12224V3.43335H17.7333V5.12224H2.53333Z" fill="white"/></g></svg>');

    @include mobile{
        display: inline-block;
    }
}

.noblog_home{
    padding-top: 110px;
}

.ez-toc-title-container, .ez-toc-cssicon-toggle-label{
    display: none !important;
}
#ez-toc-container{
    padding: 0px !important;
    background-color: transparent !important;
    border: 0px none transparent !important;
    box-shadow: none !important;
}
.post__text #ez-toc-container {
    display: none !important;
}

.inrecruiting {
    margin-top: 65px;
    padding: 103px 0px 103px 0px;
    background-image: url(../assets/images/bg-inrecruiting.jpg);
    @include bgPos(no-repeat, center top, auto auto);

    @include mobile{
        background-color: var(--cores-da-marca-elofy-blue-20);
        background-image: none;
        padding: 40px 19px 50px 19px;
    }

    &__logo {
        margin-bottom: 40px;

        @include mobile{
            margin-bottom: 12px;
            padding-left: 8px;
            padding-right: 8px;
        }

        img{
            @include mobile{
                width: 100px;
            }
        }
    }

    &__title {
        margin-bottom: 18px;

        @include mobile{
            font-size: 48px;
            text-align: left;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    &__text{
        max-width: 400px;

        @include mobile{
            padding-left: 8px;
            padding-right: 8px;
        }
        
        p{
            color: var(--cores-da-marca-soft-gray);
            font-size: 24px;
            font-weight: 500;
            line-height: normal;

            @include mobile{
                font-size: 16px;
            }
        }
    }

    &__cta {
        padding-top: 40px;

        @include mobile{
            padding-top: 22px;
        }
    }

    &__image{
        margin-top: 24px;

        img{
            border-radius: 20px;
            display: block;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}


@import "common/footer";


/*		MEDIA QUERIES		*/
@media (min-width: 1800px){
    .inrecruiting {
        background-size: cover;
    }
}
@media (max-width: 1799px){
}
@media (max-width: 1399px){
}
@media (max-width: 1299px){
}
@media (max-width: 1159px){
}
@media (max-width: 767px){
    .hide__mobile{
        display: none !important;
    }
    .inline__mobile{
        display: inline !important;
    }
    .block__mobile{
        display: block !important;
    }
    .flex__mobile{
        display: flex !important;
    }
    .iblock__mobile{
        display: inline-block !important;
    }
    .values__scrollbar{
        margin-bottom: 0px;
    }
    .testimonials__list {
        margin-bottom: 0px;
        padding-bottom: 0px;
        flex-wrap: wrap;
    }
    .flag--p22, .flag--p23, .flag--p24, .flag--p25, .graphic.graphic--10, .flag--13{
        display: none;
    }
    .img__hasmobile .wp-post-image{
        display: none !important; 
    }
}
@media (max-width: 349px){
}
@media (max-height: 699px) and (max-width: 1159px){
}