

/*      HEADER              */
.header{
    position: fixed;
    top: 0px;
    left:0px;
    z-index: 100;
    width: 100%;
    height: $headerHeight;
    border-bottom: 1px solid var(--escala-de-cinzas-cinza-3);
    background-color: var(--escala-de-cinzas-cinza-5);

    @include mobile{
        height: $headerHeightMobile;
        border-bottom: 0px none transparent;
        background-color: var(--cores-da-marca-elofy-blue-20);
    }

    &--transparent{
        @include mobile{
            background-color: var(--escala-de-cinzas-cinza-5);
        }
        
        @include mobile{
            .logo__elofy{
                background-image: url(../assets/logo/elofy.svg) !important;
            }

            .burger__btn{
                background-image: url('data:image/svg+xml,<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.533447 10.5667V8.87779H10.6668V10.5667H0.533447ZM0.533447 6.34446V4.65557H15.7334V6.34446H0.533447ZM0.533447 2.12224V0.43335H15.7334V2.12224H0.533447Z" fill="%23001BA6"/></svg>');
            }
        }
    }

    &__content{
        height: $headerHeight;
        align-items: center;

        @include mobile{
            height: $headerHeightMobile;
        }
    }

    .logo{
        @include flex(104px);

        @include mobile{
            @include flex(50%);
            display: flex;
            align-items: center;
        }

        &__elofy{
            width: 58px;
            height: 24px;
            @include bgPos(no-repeat, left top, auto auto);
            background-image: url(../assets/logo/elofy.svg);
            @extend .replace;

            @include mobile{
                width: 50px;
                height: 21px;
                background-size: 50px auto;
                background-image: url(../assets/logo/elofy-white.svg);
            }
        }
        &__zucchetti{
            margin-top: 5px;

            @include mobile{
                display: none;
            }
        }
    }

    .menu{
        @include flex(calc(100% - 454px));
        
        @include mobile{
            @include flex(100%);
            display: none;
        }

        &__list{
            @extend .flex;
            column-gap: 12px;
        }
        &__item{
            padding:16px;
            position: relative;

            &:hover .submenu{
                opacity: 1;
                visibility: visible;
                z-index: 20;
            }
            &:hover .menu__link--has_submenu{
                color: var(--cores-da-marca-new-magenta);
            }
            &:hover .menu__link--has_submenu::before{
                opacity: 0;
            }
            &:hover .menu__link--has_submenu::after{
                opacity: 1;
            }
        }
        &__link{
            color: #233042;
            font-size: 14px;
            font-weight: 500;
            position: relative;
            @extend .trn;
            &--has_submenu{
                @extend .iconHover;
                padding-right: 24px;
                @include bgPos(no-repeat, right center, auto auto);

                &::before, &::after{
                    width: 16px;
                    height: 16px;
                    right: 0px;
                    left: auto;
                }
                &::before{
                    background-image: url('data:image/svg+xml,<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 5.26671L0 1.26671L0.933333 0.333374L4 3.40004L7.06667 0.333374L8 1.26671L4 5.26671Z" fill="%23233042"/></svg>');
                }
                &::after{
                    background-image: url('data:image/svg+xml,<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 5.26671L0 1.26671L0.933333 0.333374L4 3.40004L7.06667 0.333374L8 1.26671L4 5.26671Z" fill="%23E66CF7"/></svg>');
                }
            }

            &:hover, &:active{
                color: var(--cores-da-marca-new-magenta); 
            }
        }
    }

    .submenu{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        z-index: -1;
        top: calc(100% - 4px);
        left: 2px;
        padding: 35px 50px 28px 33px;
        border-radius: 8px;
        border: 1px solid var(--escala-de-cinzas-cinza-4);
        background-color: var(--escala-de-cinzas-cinza-5);
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
        @extend .trn;
        display: flex;
        column-gap: 24px;

        &__item{
            margin-bottom: 4px;
        }

        &__title{
            color: var(--cores-software-cinza-azulado);
            font-family: $fontDefault;
            font-size: 16px;
            font-weight: 600;
            line-height: 100%;
            display: flex;
            align-items: center;
            column-gap: 8px;
            white-space: nowrap;
            padding-bottom: 6px;

            &--link{
                font-weight: 500;
            }
        }

        &__link{
            color: var(--cores-software-cinza);
            font-size: 12px;
            font-weight: 500;
            line-height: 125%;
            display: flex;
            align-items: center;
            padding: 4px;
            margin-left: 22px;
            white-space: nowrap;
            @extend .trn;

            svg{
                margin-left: 5px;
            }

            &:hover, &:active{
                color: var(--cores-da-marca-new-magenta); 
            }
        }
    }

    .actions{
        @include flex(350px);
        column-gap: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include mobile{
            @include flex(50%);
        }

        &__login_link{
            @include mobile{
                display: none;
            }
        }
    }
}

.vendas{
    @extend .flex;
    @extend .auto;
    max-width: 1028px;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-top: 60px;
    padding-bottom: 120px;

    @include mobile{
        padding-bottom: 30px;
        padding-left: 0px;
        padding-top: 30px;
    }
    
    &__title{
        max-width: 384px;
        
        @include mobile{
            text-align: left;
            font-size: 72px;
            padding-left: 25px;
            max-width: 330px;
        }
    }

    &__data{
        position: relative;
        margin-bottom: 10px;
    }

    &__image{
        border-radius: 11px;
        overflow: hidden;
        position: absolute;
        right: 16px;
        bottom: -16px;

        @include mobile{
            border-radius: 16px;
            bottom: -5px;
            right: 35px;
        }

        img{
            
            @include mobile{
                width: 145px;
            }
        }
    }

    &__contact{
        max-width: 404px;
        width: 100%;
        
        @include mobile{
            padding-top: 25px;
        }
    }

    &__field{
        margin-top: 16px;

        &--select{
            overflow: hidden;
            border: 1px solid var(--escala-de-cinzas-cinza-1);
            border-radius: 20px;
        }
    }

    &__input_text{
        display: block;
        width: 100%;
        height: 41px;
        border-radius: 20px;
        border: 1px solid var(--escala-de-cinzas-cinza-1);
        color: var(--escala-de-cinzas-cinza-1);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        padding-left: 18px;
    }

    &__select{
        display: block;
        width: calc(100% + 25px);
        height: 39px;
        color: var(--escala-de-cinzas-cinza-1);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        padding-left: 18px;
        background-image: url('data:image/svg+xml,<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.92164 5.81736L0.0878906 1.48461L1.21577 0.473633L4.92164 3.79541L8.62752 0.473633L9.7554 1.48461L4.92164 5.81736Z" fill="%23E66CF7"/></svg>');
        @include bgPos(no-repeat, calc(100% - 40px) center, auto auto);
    }

    &__obs{
        margin-top: 16px;

        &--border{
            padding-top: 16px;
            border-top: 1px solid var(--escala-de-cinzas-cinza-1); 
        }

        p{
            color: var(--escala-de-cinzas-cinza-1);
            font-size: 11px;
            font-weight: 400;
            line-height: 150%;
        }

        a{
            color: var(--footer-magenta);
        }

        svg{
            vertical-align: -10%;
        }
    }

}

.burger{
    &__header{
        height: 53px;
        border-bottom: 1px solid rgba(255, 250, 250, 0.13);
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;

        &_logo{
            display: flex;
            align-items: center;
        }

        &_login{
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .btn--login {
                height: 23px;
                font-size: 14px;
                padding-left: 14px;
                padding-right: 14px;
            }
        }
    }

    &__close_btn{
        width: 24px;
        height: 24px;
        margin-right: 16px;
        @include bgPos(no-repeat, center center, auto auto);
        background-image: url('data:image/svg+xml,<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_3795_88915" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="23"><rect x="0.5" width="23" height="23" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_3795_88915)"><path d="M6.63464 18.2082L5.29297 16.8665L10.6596 11.4998L5.29297 6.13317L6.63464 4.7915L12.0013 10.1582L17.368 4.7915L18.7096 6.13317L13.343 11.4998L18.7096 16.8665L17.368 18.2082L12.0013 12.8415L6.63464 18.2082Z" fill="white"/></g></svg>');
    }

    &__content{
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 20px;
    }

    &__title{
        color: #FFF;
        font-size: 72px;
        font-weight: 700;
        line-height: 97%;
        text-transform: uppercase;
        max-width: 260px;
    }

    &__list{
        padding-top: 32px;
    }

    &__item{
        margin-bottom: 32px;
    }

    &__link{
        display: flex;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        align-items: center;
        column-gap: 8px;

        &--href{
            font-size: 14px; 
        }

        span{
            display: flex;
            margin-left: 10px;
        }
    }

    &__sublist{
        display: none;
        padding-top: 7px;
    }

    &__subitem{
        margin-top: 15px;
    }

    &__cta{
        text-align: center;
        padding-top: 5px;
    }

    &__social{
        display: flex;
        justify-content: center;
        column-gap: 16px;
        padding-top: 25px;
        .social__icon {
            height: 27px;
            width: 26px;
        }
        .social__icon::before {
            background-size: 100% auto;
        }
    }
}
.burger__menu{
    @extend .trn;

    @include mobile{
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        background: var(--cores-da-marca-elofy-blue-20);
        overflow-y: scroll;
        padding-bottom: 40px;
    }

    &.active{
        z-index: 9999;
        visibility: visible;
        opacity: 1;
    }
}
/*      END HEADER          */